import { useDisclosure } from "@mantine/hooks";
import cn from "classnames";
import { Fragment } from "react";
import "./App.css";
import Drawer from "./components/Drawer";
import Footer from "./components/Footer";
import Header from "./components/Header";

export const wsLink = (service: string) => {
  return `https://wa.me/60123453466?text=Hello!%20I'm%20interested%20in%20learning%20more%20about%20${
    service !== "PU4LYF" ? `your%20${service}` : service
  }.%20Could%20you%20please%20provide%20more%20details?%20Thanks!`;
};

function App() {
  const menuDisclosure = useDisclosure(false);

  return (
    <Fragment>
      <div className="flex">
        <div className="hidden lg:flex flex-col min-h-screen lg:min-h-full lg:h-screen overflow-auto min-w-[84px] bg-black border-r border-primary top-0 left-0 text-secondary-text z-10">
          <div
            className="min-h-[84px] flex justify-center items-center bg-primary cursor-pointer group"
            onClick={menuDisclosure[1].toggle}
          >
            <img
              src="./assets/menu.svg"
              className="transform duration-150 group-hover:rotate-12"
            />
          </div>
          <div className="pb-6 flex flex-col-reverse mt-auto whitespace-nowrap">
            <SideNavItem href="#">HOME</SideNavItem>
            <SideNavItem href={wsLink("Customs")}>CUSTOMS</SideNavItem>
            <SideNavItem href={wsLink("Agency")}>AGENCY</SideNavItem>
            <SideNavItem href={wsLink("Label")}>LABEL</SideNavItem>
            <SideNavItem href={wsLink("Merch")}>MERCH</SideNavItem>
            <SideNavItem href="#">ABOUT US</SideNavItem>
            <SideNavItem href={wsLink("PU4LYF")}>CONTACT</SideNavItem>
          </div>
        </div>
        <main className="flex flex-col min-h-screen lg:h-screen w-screen items-center justify-center bg-black">
          <Header menuDisclosure={menuDisclosure} />

          <div className="flex flex-col lg:flex-row h-full lg:h-fit w-full justify-center gap-12 md:gap-12 lg:gap-20 lgxl:gap-24 xl:gap-16 2xl:gap-20 sm:py-12 lg:py-0 px-10">
            <SectionContainer label="CUSTOMS" href={wsLink("Customs")}>
              <img
                src="/assets/Customs.svg"
                alt="Custom"
                // className="w-32 h-32 lg:w-[163.39px] lg:h-[131.24px]"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="AGENCY" href={wsLink("Agency")}>
              <img
                src="/assets/Agency.svg"
                alt="Agency"
                // className="w-32 h-32 lg:w-[163.39px] lg:h-[131.24px]"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="LABEL" href={wsLink("Label")}>
              <img
                src="/assets/Label.svg"
                alt="Label"
                // className="w-32 h-32 lg:w-[144.56px] lg:h-[119.03px]"
              />
            </SectionContainer>
            <Divider />
            <SectionContainer label="MERCH" href={wsLink("Merch")}>
              <img
                src="/assets/Merch.svg"
                alt="Merch"
                // className="w-32 h-32 lg:w-[200.55px] lg:h-[129.23px]"
              />
            </SectionContainer>
          </div>
          <Footer />
        </main>
      </div>
      <Drawer.Main opened={menuDisclosure[0]} handlers={menuDisclosure[1]}>
        <Drawer.Body className="h-full flex">
          <div className="flex flex-col items-center justify-center gap-4 py-[22px]">
            <img
              src="/assets/Logo.svg"
              alt="Logo"
              className="h-10 cursor-pointer"
            />
            <span className="text-text font-light text-[18.85px] leading-none">
              FROM BEATS TO BUSINESS
            </span>
          </div>
          <DrawerNavItem href="#">HOME</DrawerNavItem>
          <DrawerNavItem href={wsLink("Customs")}>CUSTOMS</DrawerNavItem>
          <DrawerNavItem href={wsLink("Agency")}>AGENCY</DrawerNavItem>
          <DrawerNavItem href={wsLink("Label")}>LABEL</DrawerNavItem>
          <DrawerNavItem href={wsLink("Merch")}>MERCH</DrawerNavItem>
          <DrawerNavItem href="#">ABOUT US</DrawerNavItem>
          <DrawerNavItem href={wsLink("PU4LYF")}>CONTACT</DrawerNavItem>
          <div className="w-full block mt-auto left-0 px-4 py-6 sm:px-6 sm:py-8 md:px-10 md:py-12 pt-12">
            <div className="w-full flex flex-col items-center justify-center gap-4 xs:gap-6">
              <img
                src="/assets/footer.svg"
                alt="A PU4LYF COMPANY"
                className="w-48 h-5"
              />
              <div className="flex items-center gap-4 xs:gap-5">
                <a href="https://www.youtube.com/@PU4LYF">
                  <img
                    src="/assets/Youtube.svg"
                    alt="Youtube"
                    className="h-5 cursor-pointer"
                  />
                </a>
                <a href={wsLink("PU4LYF")}>
                  <img
                    src="/assets/Whatsapp.svg"
                    alt="Whatsapp"
                    className="h-6 cursor-pointer"
                  />
                </a>
                <a href="https://www.instagram.com/pu4lyf.ent/">
                  <img
                    src="/assets/Instagram.svg"
                    alt="Instagram"
                    className="h-6 cursor-pointer"
                  />
                </a>
                <a href="https://www.linkedin.com/in/pu4lyf-entertainment-934123120">
                  <img
                    src="/assets/LinkedIn.svg"
                    alt="LinkedIn"
                    className="h-6 cursor-pointer"
                  />
                </a>
                <a href="https://www.twitter.com/PU4LYF_ent">
                  <img
                    src="/assets/Twitter.svg"
                    alt="Twitter"
                    className="h-6 cursor-pointer"
                  />
                </a>
                <a href="https://www.facebook.com/PU4LYF.Entertainment">
                  <img
                    src="/assets/Facebook.svg"
                    alt="Facebook"
                    className="h-6 cursor-pointer"
                  />
                </a>
              </div>
              <div className="text-center">
                {/* px 89px */}
                <span
                  className="text-text font-light text-xs xs:text-sm"
                  // className="text-text font-light text-xs xs:text-[18.45px]"
                  style={{ fontFamily: "Arial Narrow" }}
                >
                  All rights reserved PU4LYF Entertainment Sdn. Bhd.
                </span>
              </div>
            </div>
          </div>
        </Drawer.Body>
      </Drawer.Main>
    </Fragment>
  );
}

export default App;

const SectionContainer = ({ children, label, href }: any) => {
  return (
    <a href={href}>
      <div className="group flex flex-col items-center justify-center gap-4 md:gap-10 lg:gap-20 cursor-pointer">
        <div className="h-full transform duration-150 group-hover:scale-110">
          {children}
        </div>
        <LinkTitle>{label}</LinkTitle>
      </div>
    </a>
  );
};

const LinkTitle = ({ children }: any) => {
  return (
    <div className="w-32 text-center">
      <span className="text-text group-hover:text-primary font-light text-[24px]">
        {children}
      </span>
    </div>
  );
};

const Divider = ({ hidden }: any) => {
  return (
    <div className="h-full hidden xl:block">
      {hidden && <div className="h-[122.93px] w-[2.5px] bg-transparent" />}
      {!hidden && <div className="h-[122.93px] w-[2.5px] bg-secondary-text" />}
      {/* h-32  */}
    </div>
  );
};

const SideNavItem = ({ href, children, className }: any) => {
  return (
    <a
      href={href}
      className={cn(
        "rotate-180 flex items-center justify-center cursor-pointer duration-150 hover:bg-primary hover:text-black py-6",
        className
      )}
      style={{ writingMode: "vertical-rl" }}
    >
      {children}
    </a>
  );
};

const DrawerNavItem = ({ href, children }: any) => {
  return (
    <a href={href}>
      <div className="flex w-full py-6 items-center justify-center cursor-pointer text-[24px] text-secondary-text hover:bg-primary hover:text-black transform duration-150">
        {children}
      </div>
    </a>
  );
};
