import { useDisclosure } from "@mantine/hooks";

interface HeaderProps {
  menuDisclosure: ReturnType<typeof useDisclosure>;
}

const Header = ({ menuDisclosure }: HeaderProps) => {
  return (
    <div className="w-full block lg:absolute top-0 left-0 px-4 py-6 sm:px-6 md:px-[84px] md:py-[22px] pb-12 h-40 sm:h-[84px]">
      <div className="lg:pl-10 h-full">
        <div className="w-full flex flex-wrap gap-6 lg:gap-10 justify-between">
          <div className="flex flex-wrap items-end gap-4 lg:gap-6">
            <img
              src="/assets/Logo.svg"
              alt="Logo"
              className="h-10 cursor-pointer"
            />
            <Divider />
            <span className="text-text font-light text-[18.85px] leading-none hidden sm:block">
              FROM BEATS TO BUSINESS
            </span>
          </div>
          <div
            className="bg-primary h-10 gap-2 px-3 items-center justify-center cursor-pointer flex lg:hidden"
            onClick={menuDisclosure[1].toggle}
          >
            <img src="./assets/menu.svg" className="h-5 w-5" />
            MENU
          </div>
          {/* px 89px */}
          {/* <div className="text-center ls:w-fit py-1 px-14 lg:px-[90px] border-[2.5px] border-button-border cursor-pointer">
            <span className="text-text font-light text-[24px]">JOIN US</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Header;

const Divider = ({ hidden }: any) => {
  return (
    <div className="h-full hidden sm:block">
      {!hidden && <div className="h-full w-[2.5px] bg-border" />}
    </div>
  );
};
