import { wsLink } from "../App";

const Footer = () => {
  return (
    <div className="w-full block lg:absolute bottom-0 left-0 px-4 py-6 sm:px-6 sm:py-8 md:px-10 md:py-12 pt-12">
      <div className="w-full flex flex-col items-center justify-center gap-4 xs:gap-6">
        <img
          src="/assets/footer.svg"
          alt="A PU4LYF COMPANY"
          className="w-48 h-5"
        />
        <div className="flex items-center gap-4 xs:gap-5">
          <a href="https://www.youtube.com/@PU4LYF">
            <img
              src="/assets/Youtube.svg"
              alt="Youtube"
              className="h-5 cursor-pointer"
              // className="w-5 h-5 xs:w-[31.41px] xs:h-[22.29px] cursor-pointer"
            />
          </a>
          <a href={wsLink("PU4LYF")}>
            <img
              src="/assets/Whatsapp.svg"
              alt="Whatsapp"
              className="h-6 cursor-pointer"
              // className="w-5 h-5 xs:w-[32.01px] xs:h-[32.3px] cursor-pointer"
            />
          </a>
          <a href="https://www.instagram.com/pu4lyf.ent/">
            <img
              src="/assets/Instagram.svg"
              alt="Instagram"
              className="h-6 cursor-pointer"
              // className="w-5 h-5 xs:w-[29.54px] xs:h-[29.43px] cursor-pointer"
            />
          </a>
          <a href="https://www.linkedin.com/in/pu4lyf-entertainment-934123120">
            <img
              src="/assets/LinkedIn.svg"
              alt="LinkedIn"
              className="h-6 cursor-pointer"
              // className="w-5 h-5 xs:w-[26.63px] xs:h-[26.7px] cursor-pointer"
            />
          </a>
          <a href="https://www.twitter.com/PU4LYF_ent">
            <img
              src="/assets/Twitter.svg"
              alt="Twitter"
              className="h-6 cursor-pointer"
              // className="w-5 h-5 xs:w-[26.45px] xs:h-[31.77px] cursor-pointer"
            />
          </a>
          <a href="https://www.facebook.com/PU4LYF.Entertainment">
            <img
              src="/assets/Facebook.svg"
              alt="Facebook"
              className="h-6 cursor-pointer"
              // className="w-5 h-5 xs:w-[13.13px] xs:h-[25.25px] cursor-pointer"
            />
          </a>
        </div>
        <div className="text-center">
          {/* px 89px */}
          <span
            className="text-text font-light text-xs xs:text-sm"
            // className="text-text font-light text-xs xs:text-[18.45px]"
            style={{ fontFamily: "Arial Narrow" }}
          >
            All rights reserved PU4LYF Entertainment Sdn. Bhd.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const Divider = ({ hidden }: any) => {
  return (
    <div className="h-full">
      {!hidden && <div className="h-full w-[2.5px] bg-border" />}
    </div>
  );
};
